import { CoursesProvider } from '../../hooks/useCourses';
import { ExpenseTrackerProvider } from '../../hooks/useExpenseTracker';
import { MessagesProvider } from '../../hooks/useMessages';
import { SidebarProvider } from '../../hooks/useSidebar';
import { ThemeProvider } from '../../hooks/useTheme';
import { UserProvider } from '../../hooks/useUser';
import { UsersProvider } from '../../hooks/useUsers';

import React from 'react';

const combinedProviders = [
    [ThemeProvider],
    [UsersProvider],
    [UserProvider],
    [MessagesProvider],
    [CoursesProvider],
    [SidebarProvider],
    [ExpenseTrackerProvider],
];

const Providers = ({ children }) => {
    return combinedProviders.reduceRight(
        (a, c) => React.createElement(c[0], c[1], a),
        children,
    );
};

export default Providers;
