import { dateToTimestamptz } from '../utils/formatters';

import { supabase } from '../services/supabase/client';
import { useUser } from './useUser';

import { createContext, useContext, useEffect, useState } from 'react';

const MessagesContext = createContext();

export const MessagesProvider = (props) => {
    //* Constants
    const channelsTable = 'test_channels';
    const messagesTable = 'test_messages';

    // Get the current user
    const { user } = useUser();

    //* Channels
    // Loading state
    const [channelsLoading, setChannelsLoading] = useState(false);
    const [currentChannelLoading, setCurrentChannelLoading] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);

    // Fetching queues
    const [channelIdsFetchQueue, setChannelIdsFetchQueue] = useState([]);

    // Channels state
    const [channels, setChannels] = useState(null);
    const [currentChannel, setCurrentChannel] = useState(null);

    //* Realtime
    const [realtime, setRealtime] = useState(false);

    const fetchChannels = async () => {
        try {
            if (!user || channelsLoading) return;
            setChannelsLoading(true);

            const { data, error } = await supabase
                .from(channelsTable)
                .select(
                    'id, name, participants, creator_id, ' +
                        messagesTable +
                        '!test_channels_last_msg_id_fkey ( content, created_at )',
                )
                .order('updated_at', { ascending: false })
                .contains('participants', `{${user.id}}`)
                .limit(20);

            if (error) throw error;

            // Each channel has an extra property `messages`
            const newChannels = data.map((channel) => {
                channel.status = 'NOT_FETCHED';
                channel.messages = [];

                // extra `latest_msg` property
                channel.latest_msg = { ...channel?.test_messages };
                delete channel?.test_messages;

                return channel;
            });

            setChannels(newChannels);
            // console.log('Fetched all channels:' + JSON.stringify(data));

            return data;
        } catch (error) {
            console.error(error);
        } finally {
            setChannelsLoading(false);
        }
    };

    const fetchChannel = async (channelId) => {
        try {
            if (!user || !channelId || currentChannelLoading) return;
            setCurrentChannelLoading(true);

            // Check if the channel is already in the list
            const channel = channels?.find(
                (channel) => channel.id === channelId,
            );

            if (channel) {
                // console.log('Channel already in list');
                return channel;
            }

            // Check if the channel is already in the queue
            const channelIdToFetch = channelIdsFetchQueue?.find(
                (id) => id === channelId,
            );

            if (channelIdToFetch) {
                // console.log('Channel already in queue');
                return;
            }

            // Add channel to the queue
            setChannelIdsFetchQueue((prevChannelIdsFetchQueue) => [
                ...prevChannelIdsFetchQueue,
                channelId,
            ]);

            // Fetch channel
            const { data, error } = await supabase
                .from(channelsTable)
                .select(
                    'id, name, participants, creator_id, ' +
                        messagesTable +
                        '!test_channels_last_msg_id_fkey ( content, created_at )',
                )
                .eq('id', channelId)
                .maybeSingle();

            if (error) throw error;

            // Add `messages` property to the channel
            data.status = 'NOT_FETCHED';
            data.messages = [];

            // extra `latest_msg` property
            data.latest_msg = { ...data?.test_messages?.[0] };
            delete data?.test_messages;

            // Add channel to the list, filtering out the old one
            setChannels((prevChannels) => {
                if (!prevChannels) return [data];

                return [
                    ...prevChannels.filter(
                        (channel) => channel.id !== channelId,
                    ),
                    data,
                ];
            });

            // Remove channel from the queue
            setChannelIdsFetchQueue((prevChannelIdsFetchQueue) => [
                ...prevChannelIdsFetchQueue.filter((id) => id !== channelId),
            ]);

            // console.log('Fetched channel with ID: ', channelId);

            return data;
        } catch (error) {
            console.error(error);
        } finally {
            setCurrentChannelLoading(false);
        }
    };

    const fetchChannelMessages = async (channelId) => {
        try {
            if (!user || !channelId || sendingMessage) return;

            // Check if the channel is already in the list
            let channel = channels?.find((channel) => channel.id === channelId);
            if (!channel) channel = await fetchChannel(channelId);
            if (!channel) return;

            // Check if the channel's status is already `FETCHED` or `FETCHING`
            if (channel.status === 'FETCHED' || channel.status === 'FETCHING') {
                // console.log('Channel already fetched');
                return;
            }

            // Set channel status to `FETCHING`
            channel.status = 'FETCHING';

            setChannels((prevChannels) =>
                prevChannels.map((c) => (c.id === channelId ? channel : c)),
            );

            // Fetch messages
            const { data, error } = await supabase
                .from(messagesTable)
                .select('id, channel_id, author_id, content, created_at')
                .order('created_at', { ascending: true })
                .eq('channel_id', channelId)
                .limit(100);

            if (error) throw error;

            // Add the messages to the channel
            channel.messages = data || [];

            // Set channel status to `FETCHED`
            channel.status = 'FETCHED';

            // Set channel's latest message
            channel.latest_msg = {
                ...channel.latest_msg,
                ...channel.messages[channel.messages.length - 1],
            };

            // Add channel to the list, filtering out the old one
            setChannels((prevChannels) =>
                prevChannels.map((c) => (c.id === channelId ? channel : c)),
            );

            // console.log('Fetched messages for channel with ID: ', channelId);

            return data;
        } catch (error) {
            console.error(error);
        }
    };

    const getChannel = async (channelId, router) => {
        try {
            if (!user || !channelId) return;

            // Check if the channel is already in the list
            let channel = channels?.find((channel) => channel.id === channelId);

            if (channel) {
                // console.log('Channel already in list');
                return channel;
            }

            // Fetch channel
            channel = await fetchChannel(channelId);

            if (!channel) {
                // console.log('Channel not found');
                if (router) router.push('/messages');

                return;
            }

            // console.log('Fetched channel with ID: ', channelId);

            return channel;
        } catch (error) {
            console.error(error);
        }
    };

    const getChannels = async () => {
        try {
            if (!user || channelsLoading) return;
            setChannelsLoading(true);

            // Check if the channels are already in the list
            if (channels) {
                // console.log('Found channels in cache');
                return channels;
            }

            // Fetch channels
            // console.log('No channels found in cache, fetching...');
            const fetchedChannels = await fetchChannels();

            return fetchedChannels;
        } catch (error) {
            console.error(error);
        } finally {
            setChannelsLoading(false);
        }
    };

    const getChannelMessages = async (channelId) => {
        try {
            if (!user || !channelId) return;

            // Fetch messages
            const messages = await fetchChannelMessages(channelId);
            // console.log('Fetched messages for channel with ID: ', channelId);

            return messages;
        } catch (error) {
            console.error(error);
        }
    };

    const getCurrentChannel = async (currentChannelId, router) => {
        if (currentChannelLoading) return;
        setCurrentChannelLoading(true);

        if (!currentChannelId) {
            // console.log(
            //     'No current channel ID provided, cannot fetch current channel',
            // );
            setCurrentChannelLoading(false);
            if (router) router.push('/messages');
            return;
        }

        if (currentChannel && currentChannel.id === currentChannelId) {
            // console.log('Found current channel in cache');
            setCurrentChannelLoading(false);
            return currentChannel;
        }

        // console.log('No current channel found in cache, fetching...');
        const fetchedCurrentChannel = await getChannel(
            currentChannelId,
            router,
        );

        // console.log('Fetched current channel: ', fetchedCurrentChannel);

        setCurrentChannel(fetchedCurrentChannel);
        setCurrentChannelLoading(false);

        return fetchedCurrentChannel;
    };

    const sendMessage = async (channelId, content) => {
        try {
            if (!user || !channelId || sendingMessage) return;
            if (!content?.trim()) return;
            setSendingMessage(true);

            // Check if content contains any links, if so, send it as a link
            const links = content.match(
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g,
            );

            // join links with newlines, then add the content without links
            const messageContent = [
                ...(links ? links.join('\n') : []),
                ...(links
                    ? content.split(links.join('\n')).slice(1)
                    : [content]),
            ].join('');

            const { data: messageData, error } = await supabase
                .from(messagesTable)
                .insert({
                    channel_id: channelId,
                    author_id: user.id,
                    content: messageContent.trim(),
                })
                .maybeSingle();

            if (error) throw error;
            // console.log('Sent message to channel with ID: ', channelId);

            // Update channel messages
            const channel = await fetchChannel(channelId);
            channel.messages = [...channel.messages, messageData];
            channel.last_msg_id = messageData.id;
            channel.latest_msg = messageData;
            channel.status = 'FETCHED';

            setChannels((prevChannels) =>
                prevChannels.map((c) => (c.id === channelId ? channel : c)),
            );

            // Convert Date to timestamptz
            const updatedAt = dateToTimestamptz(new Date());

            // Update channel latest message
            const { error: latestChannelError } = await supabase
                .from(channelsTable)
                .update({
                    last_msg_id: messageData.id,
                    updated_at: updatedAt,
                })
                .eq('id', channelId)
                .maybeSingle();

            if (latestChannelError) throw latestChannelError;
            // console.log('Updated channel with ID: ', channelId);

            // Update old channel with latest one
            setChannels((prevChannels) =>
                prevChannels.map((c) => (c.id === channelId ? channel : c)),
            );

            return messageData;
        } catch (error) {
            console.error(error);
        } finally {
            setSendingMessage(false);
        }
    };

    useEffect(() => {
        const enableRealtimeMessaging = () => {
            try {
                if (!user || !user?.id) return;

                // Enabling realtime messaging
                // console.log(
                //     '\n\n\n%cInitializing %creal-time messaging\n\n\n',
                //     'color: grey; font-weight: bold;',
                //     'color: #f0e68c',
                // );

                // Subscribe to channel messages
                const channelSubscription = supabase
                    .from(messagesTable)
                    .on('INSERT', (payload) => {
                        // console.log('New message received: ', payload.new);
                        const { author_id, channel_id } = payload.new;

                        if (author_id === user.id) return;

                        const newMessage = payload.new;

                        // console.log(
                        //     '\n\nNew real-time message:\n\n',
                        //     newMessage,
                        // );

                        setChannels((prevChannels) =>
                            prevChannels?.map((channel) => {
                                if (channel.id === channel_id) {
                                    channel.messages = [
                                        ...channel.messages,
                                        newMessage,
                                    ];
                                    channel.last_msg_id = newMessage.id;
                                    channel.latest_msg = newMessage;
                                }

                                return channel;
                            }),
                        );
                    })
                    .subscribe();

                // Enabled realtime messaging
                setRealtime(true);
                // console.log(
                //     '\n\n\n%cEnabled %creal-time messaging\n\n\n',
                //     'color: lightgreen; font-weight: bold;',
                //     'color: #f0e68c',
                // );

                return channelSubscription;
            } catch (error) {
                console.error(error);
            }
        };

        if (!user) return;
        const subscription = enableRealtimeMessaging();

        return () => {
            supabase.removeSubscription(subscription);

            // Disabling realtime messaging
            setRealtime(false);
            // console.log(
            //     '\n\n\n%cDisabled %creal-time messaging\n\n\n',
            //     'color: #ffb3b3; font-weight: bold;',
            //     'color: #f0e68c',
            // );
        };
    }, [user]);

    useEffect(() => {
        if (!channels) return;

        // Update current channel base on channels
        const channel = channels.find(
            (channel) => channel.id === currentChannel?.id,
        );

        setCurrentChannel(channel);
    }, [channels, currentChannel?.id]);

    const values = {
        //* Channels
        // All channels
        channelsLoading,
        channels,
        getChannels,
        getChannelMessages,

        // Current channel
        currentChannelLoading,
        currentChannel,
        getCurrentChannel,
        getChannel,

        //* Send message
        sendingMessage,
        sendMessage,

        //* Realtime
        realtime,
    };

    return <MessagesContext.Provider value={values} {...props} />;
};

export const useMessages = () => {
    const context = useContext(MessagesContext);

    if (context === undefined)
        throw new Error(
            `useMessages() must be used within a MessagesProvider.`,
        );

    return context;
};
