import { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [collapsed, setCollapsed] = useState(true);

    const closeSidebar = () => {
        setCollapsed(true);
    };

    const openSidebar = () => {
        setCollapsed(false);
    };

    const toggleSidebar = () => {
        setCollapsed((prev) => !prev);
    };

    const values = {
        collapsed,
        openSidebar,
        closeSidebar,
        toggleSidebar,
    };

    return (
        <SidebarContext.Provider value={values}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);

    if (context === undefined)
        throw new Error(`useSidebar() must be used within a SidebarProvider.`);

    return context;
};
