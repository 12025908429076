import { supabase } from '../services/supabase/client';

import { createContext, useContext, useState } from 'react';

const UsersContext = createContext();

export const UsersProvider = (props) => {
    // List of fetched users
    const [users, setUsers] = useState([]);

    // Fetching queue
    const [usersToFetch, setUsersToFetch] = useState([]);

    const fetchUser = async (userId) => {
        try {
            if (!userId) return;

            // Check if user is already in the list
            const user = users?.find((user) => user?.id === userId);
            if (user) {
                // console.log('User already in list');
                return user;
            }

            // Check if user is already in the queue
            const userToFetch = usersToFetch?.find(
                (user) => user.id === userId,
            );
            if (userToFetch) {
                // console.log('User already in queue');
                return;
            }

            // Add user to the queue
            setUsersToFetch((prevUsersToFetch) => [
                ...prevUsersToFetch,
                { id: userId, fetchType: 'ID' },
            ]);

            // Fetch user
            const { data, error } = await supabase
                .from('users')
                .select('id, username, display_name, bio, avatar_url')
                .eq('id', userId)
                .maybeSingle();

            if (error) throw error;
            setUsers((prevUsers) => [...prevUsers, data]);
            // console.log('Fetched user with ID: ', userId);

            return data;
        } catch (error) {
            console.error(error);
        }
    };

    const fetchUserByUsername = async (username) => {
        try {
            const { data, error } = await supabase
                .from('users')
                .select('id, username, display_name, bio, avatar_url')
                .eq('username', username)
                .maybeSingle();

            if (error) throw error;
            setUsers((prevUsers) => [...prevUsers, data]);
            // console.log('Fetched user with username: @', username);

            return data;
        } catch (error) {
            console.error(error);
        }
    };

    const getUser = async (userId) => {
        if (!userId) return;

        const user = users?.find((user) => user.id === userId);

        if (user) {
            // console.log('Found user in cache with ID: ', user?.id);
            return user;
        }

        // console.log(`User ${userId} not found in cache, fetching...`);
        return await fetchUser(userId);
    };

    const getUserByUsername = async (username) => {
        const user = users?.find((user) => user?.username === username);

        if (user) {
            // console.log('Found user in cache with username: @', user?.username);
            return user;
        }

        // console.log(`User @${username} not found in cache, fetching...`);
        return await fetchUserByUsername(username);
    };

    const values = {
        users,

        getUser,
        getUserByUsername,
    };

    return <UsersContext.Provider value={values} {...props} />;
};

export const useUsers = () => {
    const context = useContext(UsersContext);

    if (context === undefined)
        throw new Error(`useUsers() must be used within a UsersProvider.`);

    return context;
};
