import Providers from '../components/common/Providers';
import DefaultHead from '../components/custom/seo/DefaultHead';
import ToastWrapper from '../components/custom/wrappers/ToastWrapper';

import '../styles/tailwind.css';

import 'react-toastify/dist/ReactToastify.css';

import { appWithTranslation } from 'next-i18next';

function Application({ Component, pageProps }) {
    // Use the layout defined at the page level, if available
    const getLayout = Component?.getLayout || ((page) => page);

    return (
        <>
            <DefaultHead />

            <Providers>
                <ToastWrapper />
                {getLayout(<Component {...pageProps} />)}
            </Providers>
        </>
    );
}

export default appWithTranslation(Application);
