import { useTheme } from '../../../hooks/useTheme';

import { ToastContainer } from 'react-toastify';

export default function ToastWrapper() {
    const { darkMode } = useTheme();

    return (
        <ToastContainer
            theme={darkMode ? 'dark' : 'light'}
            position="bottom-left"
            newestOnTop
            limit={7}
        />
    );
}
