import { createContext, useContext, useState } from 'react';

const CoursesContext = createContext();

export const CoursesProvider = (props) => {
    //* List of course completions
    const [courseCompletions, setCourseCompletions] = useState(null);
    // Structure: { courseId: (completed_lessons) }

    //* List of lesson completions
    const [lessonCompletions, setLessonCompletions] = useState(null);
    // Structure: { courseId: [ lessonId ] }

    //* Fetch status
    const [hasCourseCompletion, setHasCourseCompletion] = useState(false);
    const [hasLessonCompletion, setHasLessonCompletion] = useState({});

    const fetchCourseCompletions = async () => {
        try {
            const response = await fetch('/api/courses/progressions');
            const data = await response.json();

            // map to [courseId: (completed_lessons)]
            const courseCompletions = {};

            data.forEach((courseProgression) => {
                const { course_id, completed_lessons } = courseProgression;
                courseCompletions[course_id] = completed_lessons;
            });

            setCourseCompletions(courseCompletions);
            setHasCourseCompletion(true);

            return data;
        } catch (error) {
            console.error(error);
        }
    };

    const getCourseCompletion = async () => {
        if (!hasCourseCompletion) return await fetchCourseCompletions();
        return courseCompletions;
    };

    const fetchLessonCompletions = async (courseId) => {
        try {
            const res = await fetch(
                `/api/courses/${courseId}/lessons/completed`,
            );
            const data = await res.json();

            // map to [courseId: [ lessonId ]]
            const lessonCompletions = [];

            data.forEach((lesson) => {
                lessonCompletions.push(lesson);
            });

            // Set the lesson completions for this course
            setLessonCompletions((prev) => ({
                ...prev,
                [courseId]: lessonCompletions,
            }));

            // Set the hasLessonCompletion for this course
            setHasLessonCompletion((prev) => ({
                ...prev,
                [courseId]: true,
            }));

            return data;
        } catch (error) {
            console.error(error);
        }
    };

    const getLessonCompletion = (courseId) => {
        if (!hasLessonCompletion[courseId])
            return fetchLessonCompletions(courseId);

        return lessonCompletions[courseId];
    };

    const markLesson = async (courseId, lessonId, completed) => {
        try {
            await fetch(
                `/api/courses/${courseId}/lessons/${lessonId}/progress`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        completed,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );

            // Update the lesson completions for this course
            if (completed) {
                setLessonCompletions((prev) => ({
                    ...prev,
                    [courseId]: [...prev[courseId], lessonId],
                }));
            } else {
                setLessonCompletions((prev) => ({
                    ...prev,
                    [courseId]: prev[courseId].filter(
                        (lesson) => lesson !== lessonId,
                    ),
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const values = {
        courseCompletions,
        hasCourseCompletion,
        getCourseCompletion,

        lessonCompletions,
        hasLessonCompletion,
        getLessonCompletion,

        markLesson,
    };

    return <CoursesContext.Provider value={values} {...props} />;
};

export const useCourses = () => {
    const context = useContext(CoursesContext);

    if (context === undefined)
        throw new Error(`useCourses() must be used within a CoursesProvider.`);

    return context;
};
