const CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 2
});

const VND_CURRENCY_FORMATTER = new Intl.NumberFormat('vi-VN', {
    currency: 'VND',
    style: 'currency',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0
});

export const formatCurrency = (value, currency = 'USD') => {
    switch (currency) {
        case 'USD':
            return CURRENCY_FORMATTER.format(value);

        case 'VND':
            return VND_CURRENCY_FORMATTER.format(value);

        default:
            return CURRENCY_FORMATTER.format(value);
    }
};

const NUMBER_FORMATTER = new Intl.NumberFormat('en-US');
const VN_NUMBER_FORMATTER = new Intl.NumberFormat('vi-VN');

export function formatNumber(number, locale = 'en-US') {
    switch (locale) {
        case 'en-US':
            return NUMBER_FORMATTER.format(number);

        case 'vi-VN':
            return VN_NUMBER_FORMATTER.format(number);

        default:
            return NUMBER_FORMATTER.format(number);
    }
}

const COMPACT_NUMBER_FORMATTER = new Intl.NumberFormat('en-US', {
    notation: 'compact',
});

const VN_COMPACT_NUMBER_FORMATTER = new Intl.NumberFormat('vi-VN', {
    notation: 'compact',
});

export function formatCompactNumber(number, locale = 'en-US') {
    switch (locale) {
        case 'en-US':
            return COMPACT_NUMBER_FORMATTER.format(number);

        case 'vi-VN':
            return VN_COMPACT_NUMBER_FORMATTER.format(number);

        default:
            return COMPACT_NUMBER_FORMATTER.format(number);
    }
}

const DIVISIONS = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' },
];

const RELATIVE_DATE_FORMATTER = new Intl.RelativeTimeFormat('en-US', {
    numeric: 'auto',
});

const VN_RELATIVE_DATE_FORMATTER = new Intl.RelativeTimeFormat('vi-VN', {
    numeric: 'auto',
});

export function formatRelativeDate(
    toDate,
    fromDate = new Date(),
    locale = 'en-US',
) {
    let duration = (toDate - fromDate) / 1000;

    for (let i = 0; i <= DIVISIONS.length; i++) {
        const division = DIVISIONS[i];
        if (Math.abs(duration) < division.amount) {
            switch (locale) {
                case 'en-US':
                    return RELATIVE_DATE_FORMATTER.format(
                        Math.round(duration),
                        division.name,
                    );

                case 'vi-VN':
                    return VN_RELATIVE_DATE_FORMATTER.format(
                        Math.round(duration),
                        division.name,
                    );

                default:
                    return RELATIVE_DATE_FORMATTER.format(
                        Math.round(duration),
                        division.name,
                    );
            }
        }
        duration /= division.amount;
    }
}

export const dateToTimestamptz = (date) =>
    date.toISOString().replace(/\.\d{3}Z$/, 'Z');

function rectifyFormat(s) {
    let b = s.split(/\D/);
    return (
        b[0] +
        '-' +
        b[1] +
        '-' +
        b[2] +
        'T' +
        b[3] +
        ':' +
        b[4] +
        ':' +
        b[5] +
        '.' +
        b[6].substr(0, 3) +
        '+00:00'
    );
}

export const tszToDate = (timestamptz) => new Date(rectifyFormat(timestamptz));
