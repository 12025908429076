import { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [darkMode, setDarkMode] = useState(false);

    useEffect(() => {
        const isDarkMode = localStorage.getItem('vhp-dark-mode');
        if (
            isDarkMode ||
            (!('vhp-dark-mode' in localStorage) &&
                window.matchMedia('(prefers-color-scheme: dark)').matches)
        ) {
            setDarkMode(true);
            document.documentElement.classList.add('dark');
        } else {
            setDarkMode(false);
            document.documentElement.classList.remove('dark');
        }
    }, [setDarkMode]);

    const updateTheme = (isDarkMode) => {
        document.documentElement.classList.toggle('dark', isDarkMode);
        localStorage.setItem('vhp-dark-mode', isDarkMode);
        setDarkMode(isDarkMode);
    };

    const toggleTheme = () => {
        updateTheme(!darkMode);
    };

    return (
        <ThemeContext.Provider value={{ darkMode, updateTheme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);

    if (context === undefined)
        throw new Error(`useTheme() must be used within a ThemeProvider.`);

    return context;
};
