import { tszToDate } from '../utils/formatters';

import { supabase } from '../services/supabase/client';
import { useUser } from './useUser';
import { useRouter } from 'next/router';

import { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const ExpenseTrackerContext = createContext();

export const ExpenseTrackerProvider = (props) => {
    const [wallets, setWallets] = useState([]);
    const [categories, setCategories] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [transactionsByDate, setTransactionsByDate] = useState(new Map());
    const [currencies, setCurrencies] = useState([]);

    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(true);

    const [selectedWallet, setSelectedWallet] = useState(null);

    const router = useRouter();
    const { user } = useUser();

    const initialize = () => {
        if (initialized) return;
        setInitialized(true);
    };

    useEffect(() => {
        if (!initialized) return;

        const fetchWallets = async () => {
            setLoading(true);

            if (user?.id) {
                const { data } = await supabase
                    .from('wallets')
                    .select(
                        `id,
                        name,
                        description,
                        balance,
                        owner_id,
                        currency_id,
                        created_at`,
                    )
                    .eq('owner_id', user.id);

                setWallets(data);
            }

            setLoading(false);
        };

        const fetchCategories = async () => {
            setLoading(true);

            if (user?.id) {
                const { data } = await supabase
                    .from('transaction_categories')
                    .select('id, name, created_at')
                    .eq('owner_id', user.id);

                setCategories(data);
            }

            setLoading(false);
        };

        const fetchTransactions = async () => {
            setLoading(true);

            if (user?.id) {
                const { data: transactions } = await supabase
                    .from('wallet_transactions')
                    .select(
                        'id, wallet_id, wallets ( name ), category_id, currency_id, amount, description, done_at, created_at',
                    )
                    .eq('owner_id', user.id)
                    .order('done_at', { ascending: false });

                setTransactions(transactions);

                const transactionsByDateMap = new Map();

                transactions.forEach((transaction) => {
                    const date = tszToDate(transaction.done_at);
                    const shortDate = `${date.getFullYear()}-${
                        date.getMonth() + 1
                    }-${date.getDate()}`;

                    if (!transactionsByDateMap.has(shortDate)) {
                        transactionsByDateMap.set(shortDate, []);
                    }

                    transactionsByDateMap.get(shortDate).push(transaction);
                });

                setTransactionsByDate((prev) => {
                    const newMap = new Map(prev);
                    transactionsByDateMap.forEach((transactions, date) => {
                        newMap.set(date, transactions);
                    });
                    return newMap;
                });
            }

            setLoading(false);
        };

        const fetchCurrencies = async () => {
            setLoading(true);

            if (user?.id) {
                const { data } = await supabase
                    .from('currencies')
                    .select('id, currency, name, symbol');

                setCurrencies(data);
            }

            setLoading(false);
        };

        fetchWallets();
        fetchCategories();
        fetchTransactions();
        fetchCurrencies();
    }, [user, initialized]);

    const addWallet = async (newWallet) => {
        if (!user) {
            toast.error('You must be logged in to create a wallet');
            return;
        }

        try {
            const { data, error } = await supabase
                .from('wallets')
                .insert({
                    name: newWallet.name,
                    description: newWallet.description,
                    balance: newWallet.balance,
                    owner_id: user.id,
                    currency_id: newWallet.currency_id,
                })
                .single();

            if (error) {
                toast.error(error.message);
                return;
            }

            if (parseInt(newWallet.balance) > 0) {
                const { data: transactionData, error } = await supabase
                    .from('wallet_transactions')
                    .insert({
                        wallet_id: data.id,
                        amount: data.balance,
                        description: 'Initial balance',
                        owner_id: data.owner_id,
                        currency_id: data.currency_id,
                    })
                    .single();

                if (error) {
                    toast.error(`Transaction error: ${error.message}`);
                    return;
                }

                const transactionsByDateMap = new Map();

                const date = tszToDate(
                    transactionData?.done_at ?? transactionData?.created_at,
                );

                const shortDate = `${date.getFullYear()}-${
                    date.getMonth() + 1
                }-${date.getDate()}`;

                const prevTransactions =
                    transactionsByDate.get(shortDate) ?? [];

                transactionsByDateMap.set(shortDate, [
                    ...prevTransactions,
                    { ...transactionData, walletName: data.name },
                ]);

                setTransactionsByDate((prev) => {
                    const newMap = new Map(prev);
                    transactionsByDateMap.forEach((transactions, date) => {
                        newMap.set(date, transactions);
                    });
                    return newMap;
                });

                setTransactions((prev) => [...prev, transactionData]);
            }

            setWallets([...wallets, { ...newWallet, id: data.id }]);
            toast.success('Wallet created successfully');
            router.push('/expenses');
        } catch (e) {
            toast.error(`Wallet error: ${e}`);
        }
    };

    const updateWallet = async (wallet) => {
        if (!user) {
            toast.error('You must be logged in to update a wallet');
            return;
        }

        try {
            const { data, error } = await supabase
                .from('wallets')
                .update({
                    name: wallet.name,
                    description: wallet.description,
                    balance: wallet.balance,
                    currency_id: wallet.currency_id,
                })
                .eq('id', wallet.id)
                .single();

            if (error) {
                toast.error(error.message);
                return;
            }

            const currentWallet = wallets?.find((w) => w.id === wallet.id);

            const currentBalance = currentWallet?.balance ?? 0;
            const newBalance = parseInt(wallet.balance);

            if (newBalance != currentBalance) {
                const { data: transactionData, error } = await supabase
                    .from('wallet_transactions')
                    .insert({
                        wallet_id: data.id,
                        amount: newBalance - currentBalance,
                        description: 'Wallet balance update',
                        owner_id: data.owner_id,
                        currency_id: data.currency_id,
                    })
                    .single();

                if (error) {
                    toast.error(`Transaction error: ${error.message}`);
                    return;
                }

                const transactionsByDateMap = new Map();

                const date = tszToDate(
                    transactionData?.done_at ?? transactionData?.created_at,
                );

                const shortDate = `${date.getFullYear()}-${
                    date.getMonth() + 1
                }-${date.getDate()}`;

                const prevTransactions =
                    transactionsByDate.get(shortDate) ?? [];

                transactionsByDateMap.set(shortDate, [
                    ...prevTransactions,
                    { ...transactionData, walletName: data.name },
                ]);

                setTransactionsByDate((prev) => {
                    const newMap = new Map(prev);
                    transactionsByDateMap.forEach((transactions, date) => {
                        newMap.set(date, transactions);
                    });
                    return newMap;
                });

                setTransactions((prev) => [...prev, transactionData]);
            }

            setWallets(wallets.map((w) => (w.id === wallet.id ? data : w)));
            toast.success('Wallet updated successfully');

            router.push('/expenses');
        } catch (e) {
            toast.error(`Wallet error: ${e}`);
        }
    };

    const deleteWallet = async (walletId) => {
        if (!user) {
            toast.error('You must be logged in to delete a wallet');
            return;
        }

        try {
            const { transactionError } = await supabase
                .from('wallet_transactions')
                .delete()
                .eq('wallet_id', walletId);

            if (transactionError) {
                toast.error(transactionError.message);
                return;
            }

            const { walletError } = await supabase
                .from('wallets')
                .delete()
                .eq('id', walletId)
                .single();

            if (walletError) {
                toast.error(walletError.message);
                return;
            }

            setWallets(wallets.filter((w) => w.id !== walletId));
            window.location.href = '/expenses';
        } catch (e) {
            toast.error(`Wallet error: ${e}`);
        }
    };

    const values = {
        initialize,
        loading,

        wallets,
        categories,
        transactions,
        transactionsByDate,
        currencies,

        selectedWallet,
        setSelectedWallet,

        addWallet,
        updateWallet,
        deleteWallet,
    };

    return <ExpenseTrackerContext.Provider value={values} {...props} />;
};

export const useExpenseTracker = () => {
    const context = useContext(ExpenseTrackerContext);

    if (context === undefined)
        throw new Error(
            `useExpenseTracker() must be used within a ExpenseTrackerProvider.`,
        );

    return context;
};
